import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Footer = ({ title }) => {
	return (
		<footer id="footer" className="footer" role="contentinfo">
			<div className="container">
				<span className="copyright">
					© Copyright {new Date().getFullYear()}&nbsp;
					<Link to="/" className="footerlink">{title}</Link>
				</span>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Footer;
